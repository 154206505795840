<template>
    <div>
        <h3>Daftar Pelatihan</h3>
        <v-row>
            <v-col md="3" sm="4" xs="6" v-for="(item) in table.items" :key="item.id">
                <v-card class="mt-2">
                    <v-img height="150" :src="item.gambar_url"></v-img>
                    <!-- <v-card-title primary-title>
                        
                    </v-card-title> -->
                    <v-card-text>
                        <h3 class="my-2" style="text-transform: capitalize;">{{ item.nama }}</h3>
                        <p v-html="item.deskripsi"></p>
                    </v-card-text>
                    <v-card-actions>
                        <!-- <v-btn small color="primary" @click="addPendaftaran(item)">
                            Daftar
                        </v-btn> -->
                        <router-link :to="{ name: 'PesertaPelatihanDaftar', params: { id: item.id } }">
                            <v-btn title="Lihat" x-small color="primary">
                                Detail
                            </v-btn>
                        </router-link>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiDelete, mdiPencil, mdiEye } from '@mdi/js'

export default {
    components: {
    },
    data() {
        return {
            icons: {
                mdiDelete,
                mdiPencil, mdiEye
            },
            tableOptions: {},
            table: {
                loading: true,
                items: [],
                totalItems: 0,
            },
        }
    },
    watch: {
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
    },
    created() {
        this.getPelatihanPeserta()
    },
    methods: {
        addPendaftaran(item) {
            const fmData = new FormData()
            fmData.append('id_pelatihan', item.id)
            axiosPostAuth('api/Pelatihan/addPendaftaran', fmData)
                .then(response => {
                    if (response.code === 200) {
                        alert(response.message)
                    }
                    else {
                        alert(response.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
        getPelatihanPeserta() {
            axiosGetAuth('api/Pelatihan/getPelatihanPeserta')
                .then(response => {
                    this.table.items = response.data
                })
                .catch(error => {
                    alert(error)
                })
        },
        // getDetail() {
        //     const fmData = new FormData()
        //     fmData.append('id', this.praktik_id)
        //     axiosPostAuth('api/Praktik/getPraktik', fmData)
        //         .then(response => {
        //             this.data = Object.assign({}, response.data)
        //         })
        //         .catch(error => {
        //             alert(error)
        //         })
        // },
        // deleteItem(item) {
        //     const conf = confirm("Apakah yakin akan dihapus?")
        //     if (conf) {
        //         const fmData = new FormData()
        //         fmData.append('id', item.id)
        //         axiosPostAuth('api/Praktik/deleteUserPraktik', fmData)
        //             .then(response => {
        //                 if (response.code === 200) {
        //                     this.getDataFromApi()
        //                 }
        //                 else {
        //                     alert(response.message)
        //                 }
        //             })
        //             .catch(error => {
        //                 alert(error)
        //             })
        //     }
        // },
    },
}
</script>